import type { MenuItem } from "~/models/MenuItem"
import { PERMISSIONS } from "./permissions"

export const profile_menu: MenuItem[] = [
    // {
    //     title: 'shoppingCard.my',
    //     icon: 'credit_card',
    //     route: 'shoppingCard.my',
    //     color: 'grey-9',
    //     can: props => props.auth.user != null
    // },
    {
        title: "orders.my",
        route: "/profile/orders",
        icon: "request_quote",
        color: "grey-9",
        can: (props) => props.auth.user != null,
    },
    {
        title: "fields.beneficiary",
        route: "/profile/beneficiaries",
        icon: "users",
        color: "grey-9",
        can: (props) => props.auth.user != null,
    },
    // {
    //     title: 'reservations.my',
    //     route: 'reservations.my',
    //     icon: 'book_online',
    //     color: 'grey-9',
    //     can: props => props.auth.user != null
    // },
]

export const navbar_menu: {
    route_name: string
    route_current: string
    show_condition: Function | null
    name: string
}[] = [
    {
        route_name: "store",
        route_current: "store",
        show_condition: null,
        name: "home.store",
    },
    {
        route_name: "shopping-card-info",
        route_current: "shopping-card-info",
        show_condition: (page: { props: { enable_shopping_card_info: any } }) =>
            page.props.enable_shopping_card_info,
        name: "home.shopping_card_info",
    },
    {
        route_name: "store.services",
        route_current: "store.services",
        show_condition: () => false,
        name: "home.services",
    },
    {
        route_name: "blogs.index",
        route_current: "blogs.index",
        show_condition: null,
        name: "blog.title",
    },
    {
        route_name: "contact",
        route_current: "contact",
        show_condition: null,
        name: "home.contact_us",
    },
    {
        route_name: "about",
        route_current: "about",
        show_condition: (page: {
            props: { information: { enable_about_us: any } }
        }) => page.props.information.enable_about_us,
        name: "home.about_us",
    },
    {
        route_name: "frequent-asks",
        route_current: "frequent-asks",
        show_condition: (page: {
            props: { information: { enable_frequent_asks: any } }
        }) => page.props.information.enable_frequent_asks,
        name: "fields.faq",
    },
]

/**
 * Main menu
 *
 * @author Abel David.
 */
export const main_menu: MenuItem[] = [
    {
        title: "nav.manage_and_configuration",
        icon: "work",
        color: "gray-9",
        children: [
            {
                title: "nav.manage",
                icon: "work",
                color: "grey-9",
                children: [
                    {
                        title: "stores.managers.title",
                        route: "/admin/managers",
                        icon: "group",
                        color: "grey-9",
                        can: "read_managers",
                        environment: "store",
                    },
                    {
                        title: "products.title",
                        icon: "inventory_2",
                        color: "grey-9",
                        children: [
                            {
                                title: "products.list",
                                route: "/products",
                                icon: "inventory_2",
                                color: "grey-9",
                                can: "read_products",
                                environment: "store",
                            },
                            {
                                title: "categories.title",
                                route: "/categories",
                                icon: "category",
                                color: "grey-9",
                                can: "read_categories",
                                environment: "store",
                            },
                            {
                                title: "attributes.title",
                                route: "/attributes",
                                icon: "extension",
                                color: "grey-9",
                                can: "read_attributes",
                                environment: "store",
                            },
                            {
                                title: "brands.title",
                                route: "/admin/brands",
                                icon: "extension",
                                color: "grey-9",
                                environment: "marketplace",
                                can: "read_brands",
                            },
                        ],
                    },
                    {
                        title: "sale.title",
                        icon: "attach_money",
                        color: "grey-9",
                        children: [
                            {
                                title: "dispatchOrders.title",
                                route: "/admin/dispatch-orders",
                                icon: "request_quote",
                                color: "grey-9",
                                can: "read_dispatch_orders",
                                environment: "store",
                            },
                        ],
                    },
                    {
                        title: "offers.title",
                        route: "/admin/offers",
                        icon: "shop",
                        color: "grey-9",
                        can: PERMISSIONS.READ_OFFERS,
                        environment: "store",
                    },
                    {
                        title: "valoration.title",
                        color: "gray-9",
                        icon: "rss_feed",
                        route: "/admin/criterias",
                        can: "criteria_and_answer",
                        environment: "store",
                    },
                    {
                        title: "products.validation_review",
                        color: "gray-9",
                        icon: "rss_feed",
                        route: "/admin/reviews",
                        can: "rating_products",
                        environment: "store",
                    },
                ],
            },
            {
                title: "settings.title",
                icon: "settings",
                color: "grey-9",
                children: [
                    {
                        title: "settings.notifications",
                        route: "/admin/stores/settings/notifications",
                        icon: "settings_suggest",
                        color: "grey-9",
                        can: PERMISSIONS.NOTIFICATIONS,
                        environment: "store",
                    },
                    {
                        title: "settings.customization",
                        route: "/admin/stores/settings/customizations",
                        icon: "figure",
                        color: "grey-9",
                        can: PERMISSIONS.CUSTOMIZATIONS,
                        environment: "store",
                    },
                ],
            },
        ],
    },
    {
        title: "nav.marketplace",
        icon: "work",
        color: "gray-9",
        children: [
            {
                title: "nav.manage",
                icon: "work",
                color: "grey-9",
                children: [
                    {
                        title: "stores.title",
                        route: "/admin/stores",
                        icon: "store",
                        color: "grey-9",
                        can: "read_stores",
                        environment: "marketplace",
                    },
                    {
                        title: "users.title",
                        route: "/admin/users",
                        icon: "group",
                        color: "grey-9",
                        can: "read_users",
                        environment: "marketplace",
                    },
                    {
                        title: "roles.title",
                        route: "/admin/roles",
                        icon: "verified_user",
                        color: "grey-9",
                        can: "read_roles",
                        environment: "marketplace",
                    },
                    {
                        title: "products.title",
                        icon: "inventory_2",
                        color: "grey-9",
                        children: [
                            {
                                title: "products.list",
                                route: "/products",
                                icon: "inventory_2",
                                color: "grey-9",
                                can: "read_products",
                                environment: "marketplace",
                            },
                            {
                                title: "categories.title",
                                route: "/categories",
                                icon: "category",
                                color: "grey-9",
                                can: "read_categories",
                                environment: "marketplace",
                            },
                            {
                                title: "brands.title",
                                route: "/admin/brands",
                                icon: "extension",
                                color: "grey-9",
                                environment: "marketplace",
                                can: "read_brands",
                            },
                        ],
                    },
                    {
                        title: "blog.title",
                        color: "grey-9",
                        icon: "rss_feed",
                        children: [
                            {
                                route: "/admin/blogs/posts",
                                title: "post.title",
                                icon: "book",
                                color: "grey-9",
                                can: "read_post",
                            },
                            {
                                route: "/admin/blogs/categories",
                                title: "blog_categories.title",
                                icon: "list",
                                color: "grey-9",
                                can: "read_post",
                            },
                            {
                                route: "/admin/blogs/keywords",
                                title: "keywords.title",
                                icon: "keyboard",
                                color: "grey-9",
                                can: "read_post",
                            },
                        ],
                    },

                    {
                        title: "sale.title",
                        icon: "attach_money",
                        color: "grey-9",
                        children: [
                            {
                                title: "invoices.title",
                                route: "/admin/invoices",
                                icon: "receipt",
                                color: "grey-9",
                                can: "read_invoices",
                                environment: "marketplace",
                            },
                            {
                                title: "orders.title",
                                route: "/admin/orders",
                                icon: "request_quote",
                                color: "grey-9",
                                can: "read_orders",
                                environment: "marketplace",
                            },
                        ],
                    },
                    {
                        title: "banners.title",
                        route: "banners.index",
                        icon: "image",
                        color: "grey-9",
                        can: "read_banners",
                        environment: "marketplace",
                    },
                    {
                        title: "report.title",
                        icon: "summarize",
                        color: "grey-9",
                        can: "read_reports",
                        environment: "marketplace",
                        children: [
                            {
                                title: "report.for_sale",
                                route: "reports.sales",
                                icon: "point_of_sale",
                                can: "read_for_sale_reports",
                                color: "grey-9",
                            },
                            {
                                title: "report.for_inventary",
                                route: "reports.inventory",
                                icon: "inventory",
                                color: "grey-9",
                                can: "read_for_inventary_reports",
                            },
                            {
                                title: "report.orders",
                                color: "grey-9",
                                icon: "list_alt",
                                route: "reports.orders",
                                can: "read_for_order_reports",
                            },
                            {
                                title: "report.movements",
                                color: "grey-9",
                                icon: "add_business",
                                route: "reports.movements",
                                can: "read_for_movement_reports",
                            },
                            {
                                title: "report.salesaccounting",
                                color: "grey-9",
                                icon: "credit_card",
                                route: "reports.salesaccounting",
                                can: "read_for_sales_accounting_reports",
                            },
                            {
                                title: "report.sold_product",
                                color: "grey-9",
                                icon: "data_thresholding",
                                route: "reports.soldProduct",
                                can: "read_for_sold_products",
                            },
                        ],
                    },
                    {
                        title: "products.validation_review",
                        color: "gray-9",
                        icon: "rss_feed",
                        route: "/admin/reviews",
                        can: "rating_products",
                        environment: "marketplace",
                    },
                ],
            },
            {
                title: "settings.title",
                icon: "settings",
                color: "grey-9",
                children: [
                    {
                        title: "settings.generals",
                        route: "/admin/settings/generals",
                        icon: "settings_suggest",
                        color: "grey-9",
                        can: ["read_settings", "update_settings"],
                        environment: "marketplace",
                    },
                    {
                        title: "settings.customization",
                        route: "/admin/settings/customization",
                        icon: "palette",
                        color: "grey-9",
                        can: ["read_settings", "update_settings"],
                        environment: "marketplace",
                    },
                    {
                        title: "Monedas",
                        route: "coins.index",
                        icon: "monetization_on",
                        color: "grey-9",
                        can: "read_coins",
                        environment: "marketplace",
                    },
                    {
                        title: "Tasa de cambio",
                        route: "exchange_rates.get-per-day",
                        icon: "account_balance",
                        color: "grey-9",
                        can: "read_exchange_rate",
                        environment: "marketplace",
                    },
                    {
                        title: "stripe-proxy.title",
                        color: "grey-9",
                        icon: "credit_card",
                        environment: "marketplace",
                        children: [
                            {
                                title: "stripe-proxy.credentials",
                                color: "grey-9",
                                icon: "credit_card",
                                route: "/admin/stripe-proxy/credentials",
                                can: PERMISSIONS.MANAGE_STRIPE_PROXY_SERVER_CREDENTIALS,
                            },
                            {
                                title: "stripe-proxy.transactions",
                                color: "grey-9",
                                icon: "credit_card",
                                route: "/admin/stripe-proxy/transactions",
                                can: PERMISSIONS.READ_STRIPE_PROXY_SERVER_TRANSACTIONS,
                            },
                        ],
                    },
                    {
                        title: "b2bauth.clients",
                        color: "grey-9",
                        icon: "group",
                        route: "/admin/b2bauth/clients",
                        can: PERMISSIONS.READ_B2B_AUTH_CLIENTS,
                        environment: "marketplace",
                    },
                ],
            },
            {
                title: "nav.analytics",
                icon: "area_chart",
                color: "grey-9",
                children: [
                    {
                        title: "analytics.visits",
                        route: "analytics.visits",
                        icon: "group",
                        color: "grey-9",
                        can: "read_analytics",
                        environment: "marketplace",
                    },
                    {
                        title: "analytics.sales",
                        route: "analytics.sales",
                        icon: "area_chart",
                        color: "grey-9",
                        can: "read_analytics",
                        environment: "marketplace",
                    },
                    {
                        title: "analytics.products",
                        route: "analytics.mostsold",
                        icon: "bar_chart",
                        color: "grey-9",
                        can: "read_analytics",
                        environment: "marketplace",
                    },
                ],
            },
        ],
    },
]
