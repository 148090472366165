<template>
    <!--Login link-->
    <NuxtLink
        :class="`${props.class} tw-hidden md:tw-flex md:tw-flex-col md:tw-gap-2 tw-transition font-right-navbar tw-rounded-full tw-items-center`"
        to="/auth/login"
        v-if="!user"
    >
        <q-icon
            name="person"
            size="30px"
            class="tw-text-customGray-300 hover:tw-text-primary hover:tw-transition-all hover:tw-duration-700"
        ></q-icon>
        <span class="tw-text-customGray-400 tw-text-base">{{
            $t("actions.register")
        }}</span>
    </NuxtLink>
    <q-btn
        :class="`${props.class} tw-hidden md:tw-flex font-right-navbar tw-text-primary tw-self-end`"
        no-caps
        dense
        flat
        :ripple="false"
        v-if="user"
    >
        <div class="tw-flex tw-flex-col tw-items-center tw-gap-2">
            <q-icon
                name="person"
                size="30px"
                class="tw-text-customGray-300 hover:tw-text-primary hover:tw-transition-all hover:tw-duration-700"
                v-if="!profileImage"
            ></q-icon>
            <img
                :src="profileImage"
                alt="profile image"
                class="tw-w-8 tw-h-8 tw-rounded-full tw-border-x tw-border-y tw-border-primary tw-overflow-hidden tw-object-contain"
            />
            <span>{{ user?.name }}</span>
        </div>
        <q-menu auto-close>
            <q-list style="min-width: 200px">
                <q-item clickable @click="profile">
                    <q-item-section class="font-right-navbar">{{
                        $t("nav.profile")
                    }}</q-item-section>
                </q-item>
                <template v-for="(p, i) in profile_menu">
                    <q-item
                        clickable
                        v-if="canShow(p)"
                        @click="goTo(p.route!)"
                        :key="i"
                    >
                        <q-item-section>{{ $t(p.title) }}</q-item-section>
                    </q-item>
                </template>
                <q-separator />
                <q-item clickable @click="logout">
                    <q-item-section class="font-right-navbar">{{
                        $t("actions.logout")
                    }}</q-item-section>
                </q-item>
            </q-list>
        </q-menu>
    </q-btn>

    <q-btn class="tw-hidden font-right-navbar" no-caps flat icon="more_vert">
        <q-menu auto-close v-if="!user">
            <q-list style="min-width: 150px">
                <q-item clickable @click="login">
                    <q-item-section>{{ $t("login.title") }}</q-item-section>
                </q-item>
            </q-list>
        </q-menu>

        <q-menu auto-close v-if="user">
            <q-list style="min-width: 150px" class="font-right-navbar">
                <q-item>
                    <q-item-section avatar>
                        <q-icon name="person" />
                    </q-item-section>
                    <q-item-section class="font-right-navbar">
                        {{ user?.name }}
                    </q-item-section>
                </q-item>
                <q-item clickable @click="profile" class="font-right-navbar">
                    <q-item-section class="font-right-navbar">{{
                        $t("nav.profile")
                    }}</q-item-section>
                </q-item>
                <template v-for="p in profile_menu">
                    <q-item clickable v-if="canShow(p)" @click="goTo(p.route!)">
                        <q-item-section>{{ $t(p.title) }}</q-item-section>
                    </q-item>
                </template>
                <q-separator />
                <q-item clickable @click="logout" class="font-right-navbar">
                    <q-item-section class="font-right-navbar">{{
                        $t("actions.logout")
                    }}</q-item-section>
                </q-item>
            </q-list>
        </q-menu>
    </q-btn>
</template>

<script setup lang="ts">
import { profile_menu } from "~/common/main_menu"
import type { MenuItem } from "~/models/MenuItem"
import { can } from "~/common/helpers"
import type { User } from "~/models/User"

const props = defineProps<{
    class?: string
}>()

const user = useSanctumUser<User>()
const { logout: lout } = useSanctumAuth()
const shopCartStore = useShopCartStore()
const currentStoreStore = useCurrentStoreStore()

const router = useRouter()

const profileImage = computed(() => {
    return user.value?.profile_photo_url
})

function login() {
    router.push("/auth/login")
}

function profile() {
    useOFetch("GET", "profile/edit")
    router.push("/profile")
}

async function logout() {
    lout().then(() => {
        shopCartStore.clearCart()
        currentStoreStore.clearCurrentStore()
        navigateTo("/", {})
        refreshNuxtData()
    })
}

function goTo(route_name: string) {
    if (route_name.includes("#")) {
        router.push("/" + route_name)
    } else {
        router.push(route_name)
    }
}

function canShow(item: MenuItem) {
    //return can(item?.can ?? null, props)
    return can()
}
</script>
