<script setup lang="ts">
import { capitalizeFirstLetter } from "~/common/helpers"
import type { TreeProps } from "./CustomTreeFilter.vue"

/**
 *
 *  Props and emits section
 *
 *
 */

const props = defineProps<{
    option: TreeProps
}>()

const emits = defineEmits<{
    (e: "onClick", value: TreeProps): void
}>()

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const dropdown = ref(false)

/**
 *
 *  Life cicle events
 *
 *
 */

onMounted(() => (dropdown.value = hasSelected(props.option)))

/**
 *
 *  Functions section
 *
 *
 */
 const hasSelected = (option: TreeProps) => {
    if (option.selected === true) return true
    if (!option.children || option.children.length === 0) return option.selected

    return option.children.some((children: TreeProps) => hasSelected(children))
}

const toggleDropdown = () => {
    dropdown.value = !dropdown.value
}

const onClick = (optionName: string) => {
    emits("onClick", optionName)
}
</script>

<template>
    <div :class="`${option.children.length === 0 && 'tw-ml-4'}`">
        <div @click="toggleDropdown" class="tw-flex tw-items-center">
            <q-icon
                name="fa-solid fa-angle-right"
                size="12px"
                color="primary"
                class="tw-text-primary"
                v-if="option.children.length > 0 && !dropdown"
            />
            <q-icon
                name="fa-solid fa-angle-down"
                size="12px"
                color="primary"
                class="tw-text-primary"
                v-if="option.children.length > 0 && dropdown"
            />
            <h6
                :class="`${hasSelected(option) ? 'tw-text-primary' : 'tw-text-customGray-400'} ${option.children.length > 0 && 'tw-ml-1'} tw-cursor-pointer tw-font-poppins`"
                @click="() => onClick(option.label)"
            >
                {{ capitalizeFirstLetter(option.label) }}
            </h6>
        </div>
        <template v-if="dropdown">
            <template v-for="(optionChild, i) in option.children" :key="i">
                <CustomTreeItem
                    :option="optionChild"
                    class="tw-ml-6"
                    @onClick="onClick"
                />
            </template>
        </template>
    </div>
</template>

<style scoped></style>
