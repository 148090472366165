<script setup lang="ts">
import CustomTreeItem from "./CustomTreeItem.vue"
/**
 *
 *  Props and emits section
 *
 *
 */

export type TreeProps = any & {
    id: number
    label: string
    selected: boolean
    value: string
    parent: number
    children: TreeProps[]
}

const props = defineProps<{
    options: TreeProps[]
}>()

const emits = defineEmits<{
    (e: "onSelect", optionName: string): void
}>()

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const route = useRoute()

const computedOptions = computed({
    get() {
        return props.options.map((option) => {
            return {
                ...option,
                id: option.id,
                value: option.value,
                label: option.label,
                parent: option.parent,
                children:
                    option.children.length > 0
                        ? selectedChildOption(option)
                        : option.children,
                selected: selectedOption(option),
            }
        })
    },
    set(newValue) {
        computedOptions.value = newValue
    },
})

/**
 *
 *  Life cicle events
 *
 *
 */

/**
 *
 *  Functions section
 *
 *
 */
const onSelect = (optionName: string) => {
    updateSelection()
    emits("onSelect", optionName)
}

const selectedChildOption = (option: TreeProps): TreeProps[] => {
    if (option.children && option.children.length > 0) {
        return option.children.map((child: TreeProps) => {
            return updateSelectionChild(child)
        })
    }
    return []
}

const updateSelectionChild = (option: TreeProps): TreeProps => {
    if (option.children && option.children.length > 0) {
        return {
            ...option,
            children: option.children.map((child: TreeProps) => {
                return updateSelectionChild(child)
            }),
            selected: selectedOption(option),
        }
    } else {
        return {
            ...option,
            selected: selectedOption(option),
        }
    }
}

const updateSelection = () => {
    computedOptions.value.map((option) => {
        return updateSelectionChild(option)
    })
}

const isParent = (option: TreeProps, name: string): boolean => {
    if (!option.children || option.children.length === 0) return false
    return option.children.some(
        (child) => child.parent === option.id && child.label === name
    )
}

const selectedOption = (option: TreeProps) => {
    if (option.label === "Todos" && !route.query.category) return true
    return (
        (route.query?.category &&
            route.query?.category === option.label.toString()) ||
        isParent(option, route.query?.category as string)
    )
}
</script>

<template>
    <div>
        <div v-if="computedOptions.length === 0">
            <h6 class="tw-text-customGray-400 tw-font-poppins">
                No hay categorias para mostrar
            </h6>
        </div>
        <template v-for="option in computedOptions">
            <CustomTreeItem :option="option" @onClick="onSelect" />
        </template>
    </div>
</template>

<style scoped></style>
