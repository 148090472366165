import type { HomeBreadCrumbs } from "~/composables/useBreadCrumbs"

export enum SUPPORTED_PAGES_ENUM {
    Home = "Home",
    Store = "Store",
    ShowProduct = "ShowProduct",
    Offers = "Offers",
    ContactUs = "ContactUs",
    FAQ = "FAQ",
    AboutUs = "AboutUs",
    Blog = "Blog",
    ShopCart = "ShopCart",
    ShopDetails = "ShopDetails",
}

export type SUPPORTED_PAGES = keyof typeof SUPPORTED_PAGES_ENUM

export const SUPPORTED_BREAD_CRUMBS_VALUES: HomeBreadCrumbs[] = [
    {
        name: SUPPORTED_PAGES_ENUM.Home,
        breadCrumbs: [{ name: "Home", route: "/" }],
    },
    {
        name: SUPPORTED_PAGES_ENUM.Store,
        breadCrumbs: [
            { name: "breadCrumbs.home", route: "/" },
            { name: "breadCrumbs.store", route: "/store" },
            { name: 'breadCrumbs.allCategories' }
        ],
    },
    {
        name: SUPPORTED_PAGES_ENUM.AboutUs,
        breadCrumbs: [
            { name: "breadCrumbs.home", route: "/" },
            { name: "breadCrumbs.aboutUs" },
        ],
    },
    {
        name: SUPPORTED_PAGES_ENUM.ShowProduct,
        breadCrumbs: [
            { name: "breadCrumbs.home", route: "/" },
            { name: "breadCrumbs.store", route: "/store" },
        ],
    },
    {
        name: SUPPORTED_PAGES_ENUM.ShopCart,
        breadCrumbs: [
            { name: "breadCrumbs.home", route: "/" },
            { name: "breadCrumbs.shopCart" },
        ],
    },
    {
        name: SUPPORTED_PAGES_ENUM.ContactUs,
        breadCrumbs: [
            { name: "breadCrumbs.home", route: "/" },
            { name: "breadCrumbs.contactUs" },
        ],
    },
    {
        name: SUPPORTED_PAGES_ENUM.ShopDetails,
        breadCrumbs: [
            { name: "breadCrumbs.home", route: "/" },
        ],
    },
    {
        name: SUPPORTED_PAGES_ENUM.Offers,
        breadCrumbs: [
            { name: "breadCrumbs.home", route: "/" },
            { name: "breadCrumbs.offers", route: "/offers" },
        ],
    },
    {
        name: SUPPORTED_PAGES_ENUM.Blog,
        breadCrumbs: [
            { name: "breadCrumbs.home", route: "/" },
            { name: "breadCrumbs.blog", route: "/blog" },
        ],
    },

]
