import { useOFetchCustom } from "~/composables/useOFetchCustom"
import type { Category } from "~/models/Category"

export const useCategoriesStore = defineStore("categories", {
    state: (): {
        categories: Category[]
        currentCategorySelected: string | null
        categoriesByCurrentShop: Category[]
        loadingCategories: boolean
    } => ({
        categories: [],
        currentCategorySelected: null,
        categoriesByCurrentShop: [],
        loadingCategories: false
    }),
    actions: {
        setCategories(categories: Category[]) {
            this.categories = categories
        },
        setCategoriesByCurrentShop(categories: Category[]) {
            this.categoriesByCurrentShop = categories
        },
        setCurrentCategorySelected(category: string) {
            this.currentCategorySelected = category
        },
        getCategories() {
            if (this.categories.length === 0) {
                this.fetchCategories()
            }
        },
        fetchCategories() {
            this.loadingCategories = true
            const { get } = useOFetchCustom("public/categories")
            get(undefined, {
                onSuccess: ({ data }) => {
                    this.setCategories(data)
                },
                onError: (error: any) => {
                    console.log(error)
                },
                onFinish: () => {
                    this.loadingCategories = false
                }
            })
        },
        getCategoriesByStore(store: string) {
            if (this.categoriesByCurrentShop.length === 0) {
                this.fetchCategoriesByStore(store)
            }
        },
        fetchCategoriesByStore(store: string) {
            this.loadingCategories = true
            const { get } = useOFetchCustom(
                `public/${store}/categories`
            )
            get(undefined, {
                onSuccess: ({ data }) => {
                    this.setCategoriesByCurrentShop(data)
                },
                onError: (error: any) => {
                    console.log(error)
                },
                onFinish: () => {
                    this.loadingCategories = false
                }
            })
        },
        findCategoryByName(name: string) {
            return (
                this.categories.find((category) => category.label === name) ||
                null
            )
        },
    },
})
