<script lang="ts" setup>
import { ref } from "vue"
import type { MenuItem } from "~/models/MenuItem"
import { useFilteredMenu } from "~/composables/LefMenu"
import type { User } from "~/models/User"
import { ROLES } from "~/common/roles"
import { useCategoriesStore } from "~/stores/categoriesStore"
import DropdownCard from "~/components/DropdownCard.vue"
import CustomTreeFilter from "~/components/CustomTreeFilter.vue"

/**
 *
 *  Props and emits section
 *
 *
 */

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const filtered_main_menu = useFilteredMenu()
const user = useSanctumUser<User>()
const can = useCan()
const router = useRouter()
const isMenuOpen = ref(false)
const route = useRoute()
const queryStore = useSearchStore()
const categoriesStore = useCategoriesStore()
const { $bus } = useNuxtApp()
const expanded = ref<number[]>([])

/**
 *
 *  Life cicle events
 *
 *
 */

onMounted(() => {
    if (route.query.category) {
        categoriesStore.setCurrentCategorySelected(
            route.query.category as string
        )
    }
})

/**
 *
 *  Functions section
 *
 *
 */

const toggleMenu = () => {
    isMenuOpen.value = !isMenuOpen.value
}

const goToOffers = () => {
    router.push({ path: "/offers" })
}

const searchByCategory = (category: string) => {
    categoriesStore.setCurrentCategorySelected(category)
    router.push({
        path: "/store",
        query: { ...route.query, category: category },
    })
}
</script>
<template>
    <div class="tw-w-full tw-sticky tw-top-0 tw-z-50" id="nav">
        <nav
            class="tw-py-1 tw-bg-black"
            v-if="
                user &&
                (user?.role?.name == ROLES.ADMIN ||
                    user?.all_permissions.length > 0 ||
                    user?.stores_manager_of.length > 0)
            "
        >
            <div
                class="max-container tw-flex tw-justify-between tw-gap-2 tw-text-white tw-text-sm tw-relative admin-top-menu"
            >
                <div class="tw-flex tw-gap-2">
                    <AdminTopMenu :menu="filtered_main_menu"></AdminTopMenu>
                </div>
                <div>
                    <SelectStore></SelectStore>
                </div>
            </div>
        </nav>
        <slot name="pre-nav"></slot>
        <nav
            class="tw-w-full tw-py-4 tw-bg-white tw-border-b tw-border-b-customGray-200"
        >
            <div
                class="max-container tw-flex tw-justify-between tw-items-center tw-relative"
            >
                <!-- Mobile Menu Button -->
                <button
                    class="lg:tw-hidden tw-text-primary hover:tw-text-secondary focus:tw-outline-none tw-cursor-pointer"
                    @click="toggleMenu"
                    style="background: none"
                >
                    <q-icon name="menu" size="36px"></q-icon>
                </button>

                <NuxtLink to="/" class="lg:tw-hidden">
                    <ApplicationLogo class="tw-w-[204px] tw-h-[80px]" />
                </NuxtLink>

                <div
                    class="tw-flex tw-gap-4 tw-justify-evenly tw-items-center lg:tw-hidden"
                >
                    <ProfileMenu class="!tw-flex !tw-flex-col" />
                    <ShopCartButton />
                </div>

                <!-- Desktop Menu (768px and larger) -->
                <div
                    v-if="!isMenuOpen"
                    class="tw-hidden lg:tw-flex tw-h-full tw-w-full"
                >
                    <HomeMenu />
                </div>
            </div>
        </nav>

        <HomeSubHeader class="tw-flex"></HomeSubHeader>
        <div
            class="tw-bg-white tw-py-4 tw-block lg:tw-hidden tw-relative max-container"
        >
            <HomeSearch />
        </div>
        <q-drawer
            v-model="isMenuOpen"
            :width="300"
            :breakpoint="700"
            elevated
            class="tw-bg-white tw-text-secondary"
        >
            <div class="tw-flex tw-flex-col tw-w-full tw-gap-2 tw-py-4">
                <NuxtLink to="/">
                    <ApplicationLogo
                        class="tw-w-[204px] tw-h-[85px] tw-mx-auto"
                    />
                </NuxtLink>
                <q-separator class="tw-h-[2px]"></q-separator>
                <ProfileMenu class="!tw-flex !tw-flex-col !tw-mx-auto" />

                <button
                    @click="goToOffers()"
                    class="tw-cursor-pointer tw-w-full tw-font-titilium tw-items-center tw-text-white tw-self-center tw-flex tw-bg-secondary tw-h-16 tw-gap-1 tw-justify-around tw-flex-wrap"
                >
                    <img src="/public/img/offer.svg" alt="offer" />
                    <span class="tw-font-bold tw-text-xl">{{
                        $t("home.products_in_offer")
                    }}</span>
                    <q-icon name="fa-solid fa-arrow-right" size="20px"></q-icon>
                </button>

                <DropdownCard
                    v-if="categories"
                    :title="$t('categories.title')"
                    opened
                >
                    <CustomTreeFilter
                        :options="categories"
                        @onSelect="searchByCategory"
                    />
                </DropdownCard>

                <slot name="mobile-left-drawer"></slot>
            </div>
        </q-drawer>
    </div>
</template>
<style scoped>
.carrito-container {
    position: relative;
    display: inline-block;
}

.carrito-cantidad {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #0674bf;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 0px 4px;
    text-align: center;
    font-size: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
